import React from "react";
import scroll from "../scroll"

export default function Footer() {
  return (
    <div>
      <footer className="footer text-white">
        <div className="container">
          <footer className="py-5">
            <div className="row">
              <div className="col-md-4 pb-4">
                <h5>About-Us</h5>
                <hr className='hr w-25 ' />
                <p>
                  BYTLAS INTLLIGENCE PRIVATE LIMITED Professional Consulting
                  from Functional Expertise for Software Solution
                  Digitalization, Process Automation, Data Analysis, BI
                  Visualization with Interactive Insights (AI) Artificial
                  intelligence, (ML) Machine Learning
                </p>
              </div>
              <div class="col-md-4 pb-4">
                <h5>Contact</h5>
                <hr className= 'hr w-25 ' />
                <p>
                  <i class="fa fa-map-marker me-3"></i>
                  <a
                    href="https://goo.gl/maps/WvQmsCerPqLVyKNNA" target={"blank"}
                    className="link-light text-decoration-none"
                  >
                    Maharashtra, Thane
                  </a>
                </p>
                <p>
                  <i class="fa fa-phone me-3"></i>
                  <a
                    href="tel:900411904"
                    className="link-light text-decoration-none"
                  >
                    +91-81698 86795
                  </a>
                </p>
                <p>
                  <i class="fa fa-envelope me-3"></i>
                  <a
                    href="mailto:support@bytlasintelligence.com"
                    className="link-light text-decoration-none"
                  >
                    support@bytlasintelligence.com
                  </a>
                </p>
              </div>
              <div className="col-md-4 pb-3">
                <form>
                  <h5>Subscribe to our newsletter</h5>
                  <hr className='hr w-25 ' />
                  <p>Monthly digest of what's new and exciting from us.</p>
                  <div className="d-flex flex-column flex-row w-100 gap-2">
                    <label for="newsletter1" className="visually-hidden">
                      Email address
                    </label>
                    <input
                      id="newsletter1"
                      type="mail"
                      className="form-control"
                      placeholder="Email address"
                    />
                    <label for="newsletter1" className="visually-hidden">
                      Meaasage
                    </label>
                    <textarea
                      id="newsletter1"
                      type=""
                      className="form-control"
                      placeholder="Message"
                    />
                    <button
                      className="btn  btn-primary rounded-pill px-5"
                      type="button"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
              <p>&copy; 2024 Bytlas Intelligence Pvt. Ltd. ,  All rights reserved.</p>
              <ul className="list-unstyled d-flex">
                <li className="ms-3">
                  <a className="link-light"  target={"_blank"} href=" https://www.facebook.com/">
                    <i className="fa fa-facebook fa-2x"  target={"_blank"}></i>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-light" href="https://www.instagram.com/" target={"_blank"}>
                    <i className="fa fa-instagram fa-2x"></i>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-light" href=" https://www.linkedin.com/in/BYTLAS INTLLIGENCE/"  target={"_blank"} >
                    <i className="fa fa-linkedin fa-2x"></i>
                  </a>
                </li> <li className="ms-3">
                  <a className="link-light" href=" https://bytlasintelligence.com"  target={"_blank"} >
                    <i className="fa fa-google fa-2x"></i>
                  </a>
                </li>

                {/* <li className="ms-3"><a
                                    href="https://m.facebook.com/people/ByssanInternational-Digitalworld/100081238291125/"
                                    target="_blank"
                                >
                                    <span class="fa fa-facebook"></span>
                                </a></li>
                                <li className="ms-3"><a href="https://twitter.com/" target="_blank">
                                    <span class="fa fa-twitter"></span>
                                </a></li>
                                <li className="ms-3"><a href="https://instagram.com/" target="_blank">
                                    <span class="fa fa-instagram"></span>
                                </a></li>
                                <li className="ms-3"><a href="https://youtube.com" target="_blank">
                                    <span class="fa fa-youtube"></span>
                                </a></li>
                                <li className="ms-3"><a href="https://github.com" target="_blank">
                                    <span class="fa fa-github"></span>
                                </a></li> */}
              </ul>
            </div>
          </footer>
        </div>
        <div id="progress">
        <span id="progress-value"><i class="fa-solid fa-arrow-up"></i></span>
        </div>
      </footer>
    </div>
  );
}
