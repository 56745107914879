import React, { useState } from 'react'
import { json, Navigate, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import swal from 'sweetalert';
import Header from './Header';


export default function Register() {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    async function signUp() {
        // alert("Shailesh Pargharmor");
        let item = { name, email, password }
        let result = await fetch("http://127.0.0.1:8000/api/register", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)
        })
        swal({
            text: "Register successfully",
            
          })
        result = await result.json();
        console.warn("result", result);
        localStorage.setItem("user-info", JSON.stringify(result));
        navigate("/login");

    }
    return (
        <>
            <div>
                <div className="container shadow my-5">
                    <div   style={{"margin-top": "8rem"}} className="row justify-content-end">
                        <div className="col-md-5 d-flex flex-column align-items-center text-white justify-content-center form order-2">
                            <h1 className="display-4 fw-bolder">Hello Friends</h1>
                            <p className="lead text-center">Enter Your Details to register</p>
                            <h5 className='mb-4'>OR</h5>
                            <NavLink to="/login" className="btn btn-outline-light rounded-pill pb-2 w-50">Login</NavLink>
                        </div>
                        <div className="col-md-6 p-5">
                            <h1 className="display-6 fw-bolder mb-5">Sign In</h1>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Username</label>
                                    <input type="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Mobile No.</label>
                                    <input type="Name" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" id="name" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" id="exampleInputPassword1" />
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">I agree terms & conditions</label>
                                </div>
                                <button onClick={signUp} className="btn btn-primary">Sign In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
