import React from "react";

export default function Gallery() {
  return (
    <div>
      <div className="container page-top">
        <div style={{ marginTop: "9rem" }} className="row">
          <div
            className="col-lg-6 col-md-8 col-xs-6 thumb"
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-delay="900"
            data-aos-easing="ease-in-quad"
          >
            <a href="/assets/bytlasintroduction.mp4" className="fancybox" rel="ligthbox">
              <video className="zoom img-fluid" controls>
                <source src="/assets/bytlasintroduction.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </a>
          </div>

          <div
            className="col-lg-6 col-md-8 col-xs-6 thumb"
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-delay="900"
            data-aos-easing="ease-in-quad"
          >
            <a href="/assets/bytlasIntelligence.mp4" className="fancybox" rel="ligthbox">
              <video className="zoom img-fluid" controls>
                <source src="/assets/bytlasIntelligence.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}
