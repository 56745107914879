import React from "react";
import { NavLink } from "react-router-dom";

export default function Servicelogined() {
  return (
    <div style={{"margin-top": "6rem"}} className="img">
      <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
              <div class="header-section">
          
              <h2 className="display-6 text-center pp-5 mb-4">Coming <b>soon</b></h2>
          
              </div>
            </div>
          </div>
     <img src="/assets/gif.gif" class="img-fluid rounded mx-auto  d-block" alt="Responsive image"></img>
    </div>

  )


}