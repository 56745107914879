import React from "react";
import { NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light shadow  ">
        <div className="container">
          <NavLink className="navbar-brand fw-bolder fs-4 me-auto" to="/">
            <img src="/assets/logo.png" className="image" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <NavLink className="navbar-brand fw-bolder fs-4 mx-auto" to="/">Startup</NavLink> */}
            {/* <NavLink className="navbar-brand fw-bolder fs-4 me-auto" to="/"><img src='/assets/logo.png' className='image' /></NavLink> */}
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <NavDropdown title="Our Company" id="basic-nav-dropdown">
                <NavDropdown.Item  ><NavLink className="nav-link" to="/about">About</NavLink></NavDropdown.Item>
                <NavDropdown.Item  ><NavLink className="nav-link" to="/Gallery">Gallery</NavLink></NavDropdown.Item>
                <NavDropdown.Item  ><NavLink className="nav-link" to="/Servicelogined">Our Team</NavLink></NavDropdown.Item>
              </NavDropdown>
              <li className="nav-item">
                <NavLink className="nav-link" to="/services">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Servicelogined">
                  Career
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            <div className="rightbox">
              <NavLink
                to="/login"
                className="btn btn-primary ms-auto px-4 rounded-pill "
              >
                <i className="fa fa-sign-in me-2"></i>Login
              </NavLink>
              {/* <NavLink to="/login" className="btn btn-outline-primary ms-auto px-4 rounded-pill">
                            <i className="fa fa-sign-in me-2"></i>Login</NavLink> */}

              <NavLink
                to="/register"
                className="btn btn-outline-primary ms-2 px-4 rounded-pill"
              >
                <i className="fa fa-user-plus me-2"></i>Register
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
