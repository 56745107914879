import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Contact() {
  const [your_name, setName] = useState("");
  const [email_address, setEmail] = useState("");
  const [phone_number, setPhone] = useState("");
  const [company_name, setCompany] = useState("");
  const [your_message, setMessage] = useState("");
  const navigate = useNavigate();
  async function contact() {
    let item = {
      your_name,
      email_address,
      phone_number,
      company_name,
      your_message
    }
    let result = await fetch("http://127.0.0.1:8000/api/contact", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)
        }) 
        result = await result.json();
        console.warn("result", result);
        navigate("/dashboard");
  }
  return (
    <div>
      <section id="contact">
        <div className="container my-5 py-5">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="fs-5 text-center mb-0">Contact Us</h3>
              <h2 className="display-6 text-center mb-4">Have Some <b>Question?</b></h2>
              <hr className='w-25 mx-auto' />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6">
              <img  data-aos="fade-right"  data-aos-duration="1000" data-aos-easing="linear" src="/assets/contact.png" alt="contact" className='w-75' />
            </div>
            <div className="col-md-6">
              <div action="">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Your Name</label>
                  <input type="text" value={your_name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" placeholder="Enter Your Name" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                  <input type="email" value={email_address} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Phone No.</label>
                  <input type="tel" value={phone_number} onChange={(e) => setPhone(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Phone No." />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Company Name</label>
                  <input type="text" value={company_name} onChange={(e) => setCompany(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="example pvt. ltd." />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Message</label>
                  <textarea value={your_message} onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                </div>
                <button type='submit' onClick={contact} className='btn btn-outline-primary rounded-pill px-4'>Send Message <i className="fa fa-paper-plane ms-2"></i></button>
              </div>
            </div>
          </div>
          <div className="iframe_content">
            <iframe className='iframe' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15072.248199982916!2d72.9613466!3d19.192492!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x847c49b34afbf8e5%3A0x21c48588ef223e4e!2sBYTLAS%20INTELLIGENCE%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1722450479169!5m2!1sen!2sin"></iframe>
          </div>
        </div>
      </section>
    </div>
  )
}
