import React from "react";
import { NavLink } from "react-router-dom";

export default function About() {
  return (
    <div>
      <section  style={{"margin-top": "5rem"}} id="about" >
        <div className="container mp-5 pp-5">
          <div className="container my-3 py-3">
            <div className="row">
              <div className="col-md-4" >
                <img  data-aos="fade-up"  data-aos-duration="500" data-aos-easing="ease-in-quad"
                  src="/assets/Illustration.png"
                  alt="about"
                  className="w-75"
                />
              </div>
              <div className="col-md-8">
                <h3 className="fs-5 mb-0">About Us</h3>
                <h2 className="display-6 mb-2">
                  Who <b>We</b> Are
                </h2>
                <hr className="w-50" />
                <p className="lead mb-4" data-aos="fade-up"  data-aos-duration="1000" data-aos-easing="ease-in-quad">
                BYTLAS Intelligence Private Limited is your trusted partner for digital transformation. 
                We excel in delivering customized software solutions, process and robotic automation, 
                data analysis, BI visualization, AI, machine learning, and cyber security. 
                Our expertise ensures seamless integration, enhanced decision-making, 
                and increased productivity. We adapt to your unique business needs, 
                driving success and growth through innovative and tailored approaches. 
                Choose BYTLAS Intelligence Private Limited for comprehensive, 
                cutting-edge consulting services that empower your business to thrive in the digital age.
                </p>
                {/* <button className="btn btn-primary me-2 rounded-pill px-4 py-2">
                  Get Started
                </button> */}
                <NavLink to="/login" className="btn btn-primary  px-4 rounded-pill" data-aos="fade-up"  data-aos-duration="1000" data-aos-easing="ease-in-quad">
                            Get Started</NavLink>
                {/* <button  className="btn btn-outline-primary me-2 rounded-pill px-4 py-2">
                  Contact Us
                </button> */}
                <NavLink to="/contact" className="btn btn-outline-primary ms-2 px-4 rounded-pill" data-aos="fade-up"  data-aos-duration="1000" data-aos-easing="ease-in-quad">
                            Contact Us</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
