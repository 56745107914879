import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import MainServices from './Components/MainServices';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Servicelogined from './Components/Servicelogined';
// import SimpleForm from './Components/SimpleForm';
import Gallery from './Components/Gallery';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<MainServices />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<SignUp />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/Servicelogined' element={<Servicelogined />} />
      </Routes>
      <Footer />
      {/* <SimpleForm/> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
